import React from 'react';
import ReactDOM from 'react-dom'
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component'
import { FaHome, FaRegHeart, FaHeart, FaThumbsDown, FaShareAltSquare, FaTwitter, FaInfoCircle } from 'react-icons/fa'
import { TwitterShareButton } from 'react-share';
import { slide as Menu } from 'react-burger-menu'
import './index.css'
import 'bulma/css/bulma.css'



class Card extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            extend: false,
            bad: false
        }
    }

    flip(){
        const extend = this.state.extend;
        this.setState({
            extend: !extend
        });
    }

    render(){
        const extend = this.state.extend;
        const bad = this.state.bad;
        return (
            <div className='card'>
                <div className='card-image'>
                    <img src={this.props.src} alt='img' onClick={() => this.flip()} onError={() => this.props.onError()}/>
                    <div className='like' onClick={() => this.props.onClick()}>
                        <FaHeart className='like-icon-fill' color={this.props.like ? '#f72649' : '#fff'} />
                        <FaRegHeart className='like-icon-border' color='#f72649' />
                    </div>
                    <div className='share'>
                        <TwitterShareButton url='https://picstream.net' title={"スキ💕 " + this.props.url.substring(0, this.props.url.length - 8) + " #ピクスト で発見"}>
                            <div className='share-icon-bg'></div>
                            <FaShareAltSquare className='share-icon' color='#84919e' />
                        </TwitterShareButton>
                    </div>
                </div>
                {extend && <div className='url'>
                    <FaThumbsDown className='bad-icon' color={bad ? '#000' : '#ccc'} onClick={() => {this.props.onClickFlag(); this.setState({bad: true})}}/>
                    <a href={this.props.url}>{this.props.url}</a>
                </div>}
            </div>
        )
    }
}

class Picstream extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            state: 'home',
            imgs: [],
            imgs_view: [],
            hasmore: true,
            likes: {},
            likes_pos: 0,
            home_pos: 0,
            move_flag: false,
        }
        this.load_likes();
    }

    load_likes(){
        axios.defaults.withCredentials = true
        axios.post(process.env.REACT_APP_PICSTREAM_BACKEND_URL + 'likes/', {withCredentials: true})
        .then(response => {
            this.setState({
                likes: response.data.likes
            });
            this.load(10);
        }).catch(err => {
            console.log(err);
        });
    }

    load(size){
        axios.post(process.env.REACT_APP_PICSTREAM_BACKEND_URL, {size: size})
        .then(response => {
            const imgs = this.state.imgs.slice();
            const imgs_set = new Set();
            for(let img of imgs){
                imgs_set.add(img[0]);
            }
            for(let img of response.data.imgs){
                if(!imgs_set.has(img[0])){
                    imgs.push(img);
                    imgs_set.add(img[0]);
                }
            }
            this.setState({
                imgs: imgs,
                imgs_view: imgs,
            })
        }).catch(err => {
            console.log(err);
        });
    }

    handleClick(filename, tweet_url){
        const likes = { ...this.state.likes };
        if(filename in likes){
            delete likes[filename];
            axios.post(process.env.REACT_APP_PICSTREAM_BACKEND_URL + 'liked-erase/', {
                file_url: filename,
                withCredentials: true
            });
        } else {
            likes[filename] = [tweet_url, Date.now()];
            axios.post(process.env.REACT_APP_PICSTREAM_BACKEND_URL + 'liked/', {
                file_url: filename,
                withCredentials: true
            });
        }
        this.setState({
            likes: likes,
        });
    }

    handleClickFlag(filename){
        axios.post(process.env.REACT_APP_PICSTREAM_BACKEND_URL + 'bad/', {
            file_url: filename,
            withCredentials: true
        });
    }

    savePos(){
        const state = this.state.state;
        if(state === 'home'){
            this.setState({
                home_pos: window.scrollY
            })
        } else if(state === 'likes'){
            this.setState({
                likes_pos: window.scrollY
            })
        }
    }

    handleClickLikes(){
        const likes = this.state.likes;
        let liked_image_list = [];
        for(let filename in likes){
            liked_image_list.push([filename, likes[filename][0], likes[filename][1]]);
        }
        liked_image_list.sort((a, b) => b[2] - a[2])
        this.savePos();
        this.setState({
            state: 'likes',
            imgs_view: liked_image_list,
            hasmore: false,
            move_flag: true
        })
    }

    handleClickHome(){
        const imgs = this.state.imgs;
        this.savePos();
        this.setState({
            state: 'home',
            imgs_view: imgs,
            hasmore: true,
            move_flag: true
        })
    }

    componentDidUpdate(){
        const state = this.state.state;
        const move_flag = this.state.move_flag;
        const likes_pos = this.state.likes_pos;
        const home_pos = this.state.home_pos;
        if(move_flag){
            if(state === 'home'){
                window.scrollTo(0, home_pos);
            } else if(state === 'likes'){
                window.scrollTo(0, likes_pos);
            }
            this.setState({
                move_flag: false
            })
        }
    }

    handleError(filename){
        const imgs = this.state.imgs;
        const imgs_view = this.state.imgs_view;
        const likes = { ...this.state.likes };
        let new_imgs = [];
        for(let record of imgs){
            if(record[0] !== filename){
                new_imgs.push(record);
            }
        }
        let new_imgs_view = [];
        for(let record of imgs_view){
            if(record[0] !== filename){
                new_imgs_view.push(record);
            }
        }
        if(filename in likes){
            delete likes[filename];
        }
        this.setState({
            imgs: new_imgs,
            imgs_view: new_imgs_view,
            likes: likes,
        })
    }

    render(){
        const loader = <div className='loading'><button className='button is-large is-white is-loading'>Loading</button></div>;
        const menu = <Menu>
            <button className="menu-item" onClick={() => this.handleClickHome()}><FaHome className="menu-item-icon"/><span className="menu-item-text">Home</span></button>
            <button className="menu-item" onClick={() => this.handleClickLikes()}><FaHeart className="menu-item-icon"/><span className="menu-item-text">Likes</span></button>
            <button className="menu-item" onClick={() => window.open('https://twitter.com/picstreamnet')}><FaTwitter className="menu-item-icon"/><span className="menu-item-text">Twitter</span></button>
            <button className="menu-item" onClick={() => {const $target = document.getElementById('modal-about'); $target.classList.add('is-active');}}><FaInfoCircle className="menu-item-icon"/><span className="menu-item-text">About</span></button>
        </Menu>;
        const imgs = this.state.imgs_view;
        const likes = this.state.likes;
        const hasmore = this.state.hasmore;
        if (this.state.imgs.length === 0) {
            return (
                <div>
                    {menu}
                    {loader}
                </div>
            )
        }
        let imgs_list = [];
        for(let i = 0; i < imgs.length; i++){
            imgs_list.push(
                <Card
                    key={imgs[i][0]}
                    src={imgs[i][0]}
                    url={imgs[i][1]}
                    like={imgs[i][0] in likes}
                    onClick={() => this.handleClick(imgs[i][0], imgs[i][1])}
                    onClickFlag={() => this.handleClickFlag(imgs[i][0])}
                    onError={() => this.handleError(imgs[i][0])}
                />
            );
        }
        return (
            <div>
                {menu}
                <div className='app' id='app'>
                    <InfiniteScroll
                        dataLength={imgs_list.length}
                        next={() => {this.load(10)}}
                        hasMore={hasmore}
                        loader={loader}
                    >
                        {imgs_list}
                    </InfiniteScroll>
                </div>
                <div id='modal-about' className='modal'>
                    <div className='modal-background' onClick={() => {const $target = document.getElementById('modal-about'); $target.classList.remove('is-active');}}></div>
                    <div className='modal-content'>
                        <div className="box">
                            <h1>Picstream とは？</h1>
                            <p>Twitter からお気に入りのかわいい画像を探し出せるサービスです。</p>
                            <p>Q: イラストを使用するのは良いのか？</p>
                            <p>A: Twitter にアップロードした画像は Twitter 社の管理下にあります。Twitter 関連アプリの開発者は Twitter コンテンツをエンドユーザーに対して表示することを<a href='https://developer.twitter.com/ja/developer-terms/agreement-and-policy' target='_blank' rel='noopener noreferrer'>開発者契約およびポリシー</a>にて Twitter 社より認められています。しかし、もしイラストの投稿者さんで、picstream で表示してほしくないという方がいらっしゃいましたら、表示しないよう設定いたしますので、お手数ですが <a href='https://twitter.com/picstreamnet' target='_blank' rel='noopener noreferrer'>@picstreamnet</a> まで連絡ください。</p>
                        </div>
                    </div>
                    <button className="modal-close is-large" aria-label="close" onClick={() => {const $target = document.getElementById('modal-about'); $target.classList.remove('is-active');}}></button>
                </div>
            </div>
        )
    }
}

ReactDOM.render(
    <Picstream />,
    document.getElementById('root')
)
